<template>
	<div>
		<div class="outer-wrapper"> <!-- Outer wrapper handles global media queries (max screen widths etc) -->

			<div class="inner-wrapper"> <!-- Inner wrapper handles global padding -->
				<div class="heading-section">
					<h1 class="heading4">My Friends</h1>
				</div>

				<Loader :loading="loading" />

				<div class="friends" v-if="!loading">
					<!-- {{ travellers }} -->
					<div class="traveller-wrapper">
						<AddTraveller 
							text="Add new friend"
						/> 

					</div>

					<div class="traveller-wrapper"
						v-for="traveller in travellers"
						v-bind:key="traveller.passenger_id"
					>
						<TravellerSelector 
							:travellerID="traveller.passenger_id"
							:firstName="traveller.data.firstName"
							:lastName="traveller.data.lastName"
							:dob="traveller.data.dob"
							:passportNumber="traveller.data.passportNumber"
							:passportExpiry="traveller.data.passportExpiry"
							:nationality="traveller.data.countryOfIssue"
						/>
					</div>
				</div>
			</div>

		</div><!-- end of outer wrapper -->

	</div>
	
</template>


<script>
import Menu from '@/components/Menu.vue'
import TravellerSelector from '../../components/travellers/TravellerSelector.vue';
import AddTraveller from '../../components/travellers/AddTraveller.vue';
import { travellerService } from '@/services/travellerService.js';
import Loader from '@/components/Loader.vue';
import router from '@/router';

export default {
    data () {
        return {
			loading: true,

			travellers: [],
        }
	},
	components: {
		Menu,
		TravellerSelector,
		AddTraveller,
		Loader,
	},
    computed: {
	
    },
    methods: {
      
	},
	created() {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'back',
				route: this.$route.query.redirect ? this.$route.query.redirect : { name: 'home' },
			},
			right: {
				type: 'menu',
			},
		});
		
		this.loading = true;
		travellerService.fetchUserTravellers()
			.then(response => {
				Object.keys(response.data.data).forEach((index) => { // convert passengers to array
					let passenger = response.data.data[index];
					passenger.data = JSON.parse(passenger.data);
					passenger.passenger_id = passenger.id;

					this.travellers.push(passenger);
				});
				this.loading = false;
			});
	},
};
</script>


<style scoped>
	.heading-section {
		text-align: left;
	}

	.friends {
		margin-top: 48px;
	}
	.friends .traveller-wrapper {
		text-align: center;
	}
</style>